import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import Chip from '@mui/material/Chip';
import CustomPagination from "./CustomPagination";
import FilteredTable from "./FilteredTable";
import axios from "axios";
import useDebounce from "../useDebounce";
import { useTranslation } from 'react-i18next';

const Publication = ({
  currentPage,
  setCurrentPage,
  filter = {},      
  cleanedFilter = {},  
  setFilter,
  setSearchTerm,
  Link,
  bot,
  Pagination,
  totalData,
  searchTerm,
  tableRef,
  handleExcel,
  a,
  loading,
  companies: externalCompanies,
  filterChanged,
  getCompanies,
  dateRange,
  UnSelectAll
}) => {
  const [itemCanton, setItemCanton] = useState(filter.canton || []);
  const [itemLegal, setItemLegal] = useState(filter.companyType || []);
  const [itemNationality, setItemNationality] = useState(filter.nationality || []);
  const [itemSectors, setItemSectors] = useState(filter.purposes || []);
  const [itemServices, setItemServices] = useState(filter.services || []);
  const [itemSearchTerm, setItemSearchTerm] = useState(filter.searchTerm || '');
  const [localDateRange, setLocalDateRange] = useState({ startDate: null, endDate: null });
  const [companies, setCompanies] = useState([]);

  const { t } = useTranslation();

  // Calculate date range whenever companies change
  useEffect(() => {
    const companiesToUse = filter.searchTerm ? companies : externalCompanies;
    
    if (companiesToUse && companiesToUse.length > 0) {
      const zefixDates = companiesToUse
        .map(company => company.zefixDate ? new Date(company.zefixDate) : null)
        .filter(date => date && !isNaN(date.getTime()));

      if (zefixDates.length > 0) {
        setLocalDateRange({
          startDate: new Date(Math.min(...zefixDates)),
          endDate: new Date(Math.max(...zefixDates))
        });
      }
    }
  }, [companies, externalCompanies, filter.searchTerm]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      try {
        const token = localStorage.getItem("TOKEN");
        const response = await axios.get('https://api.kseco.eu/api/companies', {
          params: {
            search: searchValue,
            canton: filter.canton,
            nationality: filter.nationality,
            purposes: filter.purposes,
            services: filter.services,
            publication: filter.publication,
            page: currentPage
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        if (response.data && response.data.data) {
          setCompanies(response.data.data);
          
          // Calculate date range from zefix dates in response
          const zefixDates = response.data.data
            .map(company => company.zefixDate ? new Date(company.zefixDate) : null)
            .filter(date => date && !isNaN(date.getTime()));

          if (zefixDates.length > 0) {
            setLocalDateRange({
              startDate: new Date(Math.min(...zefixDates)),
              endDate: new Date(Math.max(...zefixDates))
            });
          }
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    }, 500),
    [filter, currentPage]
  );

  useEffect(() => {
    setCompanies(externalCompanies);
  }, [externalCompanies]);

  useEffect(() => {
    setItemCanton(filter.canton || []);
    setItemLegal(filter.companyType || []);
    setItemNationality(filter.nationality || []);
    setItemSectors(filter.purposes || []);
    setItemServices(filter.services || []);
    setItemSearchTerm(filter.searchTerm || '');
  }, [filter]);

  const handleDelete = (key, value) => () => {
    let updatedFilter = { ...filter };
  
    if (Array.isArray(updatedFilter[key])) {
      updatedFilter[key] = updatedFilter[key].filter(item => item !== value);
      if (updatedFilter[key].length === 0) {
        delete updatedFilter[key];
      }
    } else {
      delete updatedFilter[key];
    }
  
    setFilter(updatedFilter);
    
    switch(key) {
      case 'canton':
        setItemCanton(prev => prev.filter(item => item !== value));
        break;
      case 'companyType':
        setItemLegal(prev => prev.filter(item => item !== value));
        break;
      case 'nationality':
        setItemNationality(prev => prev.filter(item => item !== value));
        break;
      case 'purposes':
        setItemSectors(prev => prev.filter(item => item !== value));
        break;
      case 'services':
        setItemServices(prev => prev.filter(item => item !== value));
        break;
      default:
        break;
    }
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const updateText = (event) => {
    const value = event.target.value;
    setItemSearchTerm(value);
    setFilter(prevFilter => ({
      ...prevFilter,
      searchTerm: value
    }));
    debouncedSearch(value);
  };

  const convertedFilter = {
    canton: itemCanton,
    companyType: itemLegal,
    nationality: itemNationality,
    purposes: itemSectors,
    services: itemServices,
  };

  const publicationFilter = cleanedFilter.publication || t('dashboard.publication.noPublication');

  return (
    <section className="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">
      <p className="resu">{t('dashboard.publication.title')}</p>
      <p>{t('dashboard.publication.dataRange')} {formatDate(localDateRange.startDate)} {t('dashboard.publication.to')} {formatDate(localDateRange.endDate)}</p>
      <div className="paraqitja">
        <div className="publication">
          <div className="filter"><b>{t('dashboard.publication.filters.publication')}:</b>{publicationFilter}</div>
        </div>
        <div>
          <div className="filter"><b>{t('dashboard.publication.filters.canton')}:</b>
            {itemCanton.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('canton', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>{t('dashboard.publication.filters.legalForm')}:</b>
            {itemLegal.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('companyType', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>{t('dashboard.publication.filters.nationality')}:</b>
            {itemNationality.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('nationality', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>{t('dashboard.publication.filters.sectors')}:</b>
            {itemSectors.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('purposes', data)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="filter"><b>{t('dashboard.publication.filters.services')}:</b>
            {itemServices.map(data => (
              <Chip
                key={data}
                label={data}
                onDelete={handleDelete('services', data)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="ser">
        <input
          className="ch"
          type="text"
          placeholder={`\uF52A ${t('dashboard.publication.search.placeholder')}`}
          value={itemSearchTerm}
          onChange={updateText}
        />
        <button onClick={handleExcel} className="exc">
          <img src={a} alt="Excel Export" />
        </button>
      </div>

      <div className="relative">
        <div className={loading ? 'opacity-50' : ''}>
          <FilteredTable
            companies={filter.searchTerm ? companies : externalCompanies}
            searchTerm={itemSearchTerm}
            convertedFilter={convertedFilter}
            publicationFilter={publicationFilter}
            loading={loading}
            tableRef={tableRef}
          />
        </div>

        {loading && (
          <div className="loading-overlay" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            height: 'auto',
            zIndex: 50
          }}>
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <div className="loading-text">{t('dashboard.publication.loading')}</div>
            </div>
          </div>
        )}
      </div>

      <div className="my-3">
        <CustomPagination
          totalData={totalData}
          currentPage={currentPage}
          itemsPerPage={10}
          onPageChange={setCurrentPage}
        />
      </div>
    </section>
  );
};

export default Publication;
