import { Nav, Navbar, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import a from '../../images/output-onlinepngtools.png';
import './navbar.css';
import axios from 'axios';
import '../Login/login.css';

import { IconShoppingCartFilled, IconChevronDown, IconWorld } from '@tabler/icons-react';

const Navigationbar = ({ toggleSidebar }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLang(lng);
    setIsLangOpen(false);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [users, setUsers] = useState(null); // Initialize users state as null

  const getUsers = async () => {
    const token = localStorage.getItem("TOKEN");
    try {
      const response = await axios.get("https://api.kseco.eu/api/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data); // Log the response data to check its structure
      setUsers(response.data); // Update users state with response data
      console.log(users); // Log the users state variable to double-check its content
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error (e.g., show error message to the user)
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  console.log(users)

  const [isLogged, setisLogged] = useState(false);

  useEffect(() => {
    checkStorage();
    return () => {};
  }, [isLogged]);

  function checkStorage() {
    if (localStorage.getItem('TOKEN')) {
      setisLogged(true);
    } else {
      setisLogged(false);
    }
  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const Logout = async () => {
    try {
      localStorage.removeItem('TOKEN');
      navigate('/');
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };

  const Auth = async (e) => {
    e.preventDefault();
    try {
      const login = await axios.post('https://api.kseco.ch/auth/login', {
        email: email,
        password: password,
      });
      localStorage.setItem('TOKEN', login.data.token);
      localStorage.setItem('user', JSON.stringify(users))
      navigate('/Dashboard');
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <div className="header_top_menu pt-2 pb-2 bg_color">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-sm-8">
              <div className="header_top_menu_address">
                <div className="header_top_menu_address_inner">
                  <ul>
                    <li><a href="#"><i className="bi bi-envelope"></i>example@gmail.com</a></li>
                    <li><a href="#"><i className="bi bi-geo-alt-fill"></i>24/5, 1st Floor, Kurigram</a></li>
                    <li><a href="#"><i className="bi bi-telephone"></i>+ (1800) 456 7890</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="header_top_menu_icon">
                <div className="header_top_menu_icon_inner">
                  <ul>
                    <li><a href="#"><i className="fa bi bi-facebook"></i></a></li>
                    <li><a href="#"><i className="fa bi bi-twitter"></i></a></li>
                    <li><a href="#"><i className="fa bi bi-pinterest"></i></a></li>
                    <li><a href="#"><i className="fa bi bi-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Navbar collapseOnSelect expand="sm" variant="dark" className="navbar">
        <Navbar.Brand as={Link} to="/">
          <img src={a} className="logoja" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto">
            <NavLink eventKey="1" as={Link} to="/home">
              {t('navigation.home')}
            </NavLink>
            <NavLink eventKey="2" as={Link} to="/shop">
              {t('navigation.shop')}
            </NavLink>
            <NavLink eventKey="3" as={Link} to="/marketing">
              {t('navigation.marketing')}
            </NavLink>
            <NavLink eventKey="4" as={Link} to="/admin">
              <i className="bi bi-speedometer2"></i> {t('navigation.dashboard')}
            </NavLink>
            {isLogged ? (
              <NavLink eventKey="5" as={Link} to="/Dashboard" className="lista">
                <button className="isi">
                  <i className="icon-equalizer warning font-large-2"></i>
                  <span className="list-builder-text">{t('navigation.myDashboard')}</span>
                </button>
              </NavLink>
            ) : (
              <NavLink eventKey="6" onClick={togglePopup} className="lista">
                <button className="isi">
                  <i className="icon-equalizer warning font-large-2"></i>
                  <span className="list-builder-text">{t('navigation.login')}</span>
                </button>
              </NavLink>
            )}
          </Nav>

          <div className="d-flex align-items-center">
            {isLogged && (
              <div className="user-profile-wrapper">
                <div className="dropdown">
                  <button className="user-profile-button dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {users && (
                      <div className="user-info">
                        <i className="bi bi-person per"></i>
                        <span className="user-name">{users.data.name}</span>
                      </div>
                    )}
                  </button>
                  <div className="dropdown-menu account" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#">{t('navigation.myAccount')}</a>
                    <a className="dropdown-item" href="#">
                      <button onClick={Logout} className="logout-button">
                        <i className="bi bi-box-arrow-right"></i>
                        <span>{t('navigation.logout')}</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            )}

            <div className="language-selector">
              <button 
                className="lang-button"
                onClick={() => setIsLangOpen(!isLangOpen)}
              >
                <i className="bi bi-globe2"></i>
                <span>{currentLang.toUpperCase()}</span>
                <i className={`bi bi-chevron-down arrow ${isLangOpen ? 'open' : ''}`}></i>
              </button>
              {isLangOpen && (
                <div className="lang-dropdown">
                  <div className="lang-option" onClick={() => handleLanguageChange('en')}>
                    <span className="flag">🇬🇧</span>
                    <span>English</span>
                  </div>
                  <div className="lang-option" onClick={() => handleLanguageChange('al')}>
                    <span className="flag">🇦🇱</span>
                    <span>Albanian</span>
                  </div>
                  <div className="lang-option" onClick={() => handleLanguageChange('fr')}>
                    <span className="flag">🇫🇷</span>
                    <span>French</span>
                  </div>
                  <div className="lang-option" onClick={() => handleLanguageChange('de')}>
                    <span className="flag">🇩🇪</span>
                    <span>Deutsch</span>
                  </div>
                </div>
              )}
            </div>

            <div className="shopping-cart-wrapper">
              <button className="shopping-cart-button" onClick={toggleSidebar}>
                <IconShoppingCartFilled size={20} />
              </button>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>

      {isOpen && (
        <div className="popup-box">
          <div className="box">
            <span className="close-icon" onClick={togglePopup}>x</span>
            <section className="hero has-background-grey-light is-fullheight is-fullwidth">
              <link
                href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                rel="stylesheet"
              />
              <div className="login">
                <div className="form">
                  <span className="close-icon" onClick={handleClose}>
                    x
                  </span>
                  <form className="login-form" onSubmit={Auth}>
                    <p className="has-text-centered">{msg}</p>
                    <span className="material-icons">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button>Login</button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigationbar;
