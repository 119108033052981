/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom/client';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import axios from 'axios';
import './details.css';
import Navbar from '../Navbar/Navbar';
import 'jspdf-autotable';
import { Graph } from "react-d3-graph";
import {CustomNode} from 'react';
import { COMPANY_API, SECTOR_API, COMPANY_TYPE_API } from '../../api/api';
import { DataSet, Network } from 'vis-network/standalone';
import { Building2, User2, Network as NetworkIcon } from 'lucide-react';
import { generatePDF } from '../../utils/pdfGenerator.js';
import CompanyRegisterPDF from '../PDFView/CompanyRegisterPDF';
import { NotificationContext } from '../Details/NotificationContext';

function Details() {
  const { t } = useTranslation();
  const [data, setData] = useState({ nodes: [], links: [] });
  const [details, setDetails] = useState({}); 
  const [sectors, setSectors] = useState([]); 
  const [company, setCompany] = useState({}); 
  const [sectorInfo, setSectorInfo] = useState(""); 
  const [currentCompany, setCurrentCompany] = useState({});
  const [companyHistory, setCompanyHistory] = useState([]);
  const { id } = useParams(); 
  const containerRef = React.useRef(null);
  let networkInstance = null; 
  const [inferredSectors, setInferredSectors] = useState([]);
  const [activeDateId, setActiveDateId] = useState(null);

  const { addNotification } = useContext(NotificationContext);
  
const toggleActiveDate = (clickedItem) => {
    console.log(`Toggling Date of Acts for: ${clickedItem.dateOfAct}`);
    console.log('Current details state:', details);

    const isActive = clickedItem.activeDataDateOfAct;

    try {
      if (isActive) {
        setActiveDateId(null);
        setDetails(prevDetails => {
          if (!prevDetails) {
            console.warn('prevDetails is null');
            return {};
          }

          return {
            ...prevDetails,
            shares: Array.isArray(prevDetails.shares) ? prevDetails.shares.map(item => ({ ...item, activeDataShares: false })) : [],
            capital: prevDetails.capital ? { ...prevDetails.capital, activeDataCapital: false } : null,
            registrationOffice: prevDetails.registrationOffice ? (
              Array.isArray(prevDetails.registrationOffice) 
                ? prevDetails.registrationOffice.map(item => ({ ...item, activeDataRegistration: false }))
                : { ...prevDetails.registrationOffice, activeDataRegistration: false }
            ) : null,
            addresses: Array.isArray(prevDetails.addresses) ? prevDetails.addresses.map(item => ({ ...item, activeDataAddress: false })) : [],
            purposes: Array.isArray(prevDetails.purposes) ? prevDetails.purposes.map(item => ({ ...item, activeDataPurpose: false })) : [],
            remarks: Array.isArray(prevDetails.remarks) ? prevDetails.remarks.map(item => ({ ...item, activeDataRemark: false })) : [],
            city: prevDetails.city ? { ...prevDetails.city, activeDataCity: false } : null,
            persons: Array.isArray(prevDetails.persons) ? prevDetails.persons.map(item => ({ ...item, activeDataPerson: false })) : [],
            dateOfActs: Array.isArray(prevDetails.dateOfActs) ? prevDetails.dateOfActs.map(item => ({ ...item, activeDataDateOfAct: false })) : [],
          };
        });
      } else {
        setActiveDateId(clickedItem.id);
        const companyId = clickedItem.companyId;

        if (!companyId) {
          console.error('Company ID is invalid:', clickedItem);
          return;
        }

        setDetails(prevDetails => {
          if (!prevDetails) {
            console.warn('prevDetails is null');
            return {};
          }

          return {
            ...prevDetails,
            shares: Array.isArray(prevDetails.shares) ? prevDetails.shares.map(item => ({
              ...item,
              activeDataShares: item.companyId === companyId,
            })) : [],
            capital: prevDetails.capital ? { 
              ...prevDetails.capital, 
              activeDataCapital: true 
            } : null,
            registrationOffice: prevDetails.registrationOffice ? (
              Array.isArray(prevDetails.registrationOffice)
                ? prevDetails.registrationOffice.map(item => ({
                    ...item,
                    activeDataRegistration: item.companyId === companyId,
                  }))
                : {
                    ...prevDetails.registrationOffice,
                    activeDataRegistration: true
                  }
            ) : null,
            addresses: Array.isArray(prevDetails.addresses) ? prevDetails.addresses.map(item => ({
              ...item,
              activeDataAddress: item.companyId === companyId,
            })) : [],
            purposes: Array.isArray(prevDetails.purposes) ? prevDetails.purposes.map(item => ({
              ...item,
              activeDataPurpose: item.companyId === companyId,
            })) : [],
            remarks: Array.isArray(prevDetails.remarks) ? prevDetails.remarks.map(item => ({
              ...item,
              activeDataRemark: item.companyId === companyId,
            })) : [],
            city: prevDetails.city ? {
              ...prevDetails.city,
              activeDataCity: true
            } : null,
            persons: Array.isArray(prevDetails.persons) ? prevDetails.persons.map(item => ({
              ...item,
              activeDataPerson: item.companyId === companyId,
            })) : [],
            dateOfActs: Array.isArray(prevDetails.dateOfActs) ? prevDetails.dateOfActs.map(item => ({
              ...item,
              activeDataDateOfAct: item.companyId === companyId,
            })) : [],
          };
        });
      }
    } catch (error) {
      console.error('Error in toggleActiveDate:', error);
      addNotification('Error updating date of acts', 'error');
    }
  };

  const getDetails = async () => {
    const token = localStorage.getItem('TOKEN');
    try {
      // Fetch company data
      const companyResponse = await axios.get(COMPANY_API.getCompanyById(id), {
        headers: { Authorization: `Bearer ${token}` },
      });
      const companyData = companyResponse.data.data || {};
      setDetails(companyData);
      setCompany(companyData);

      // Create a Set to track used person IDs
      const usedPersonIds = new Set();
      
      // Create nodes with unique IDs
      const nodes = [
        {
          id: `company-${companyData.genUniqueId}`,
          label: companyData.companyName || 'Company Name',
          group: 'company',
          type: 'company',
          genUniqueId: companyData.genUniqueId  // Store genUniqueId for API calls
        }
      ];

      // Create edges with unique IDs
      const links = [];

      // Add person nodes with unique IDs
      if (companyData.persons && companyData.persons.length > 0) {
        companyData.persons.forEach(person => {
          // Create a unique ID for this person
          let personNodeId = `person-${person.id}`;
          let counter = 1;
          
          // If this ID is already used, append a number until we get a unique ID
          while (usedPersonIds.has(personNodeId)) {
            personNodeId = `person-${person.id}-${counter}`;
            counter++;
          }
          
          // Add the ID to our tracking Set
          usedPersonIds.add(personNodeId);
          
          nodes.push({
            id: personNodeId,
            label: person.personalData || 'Unknown Person',
            group: 'person',
            type: 'person',
            originalId: person.id,
            personalData: person.personalData,
            uniqueId: person.uniqueId,
            activeData: person.activeDataPerson
          });
          
          links.push({
            from: personNodeId,
            to: `company-${companyData.genUniqueId}`,
            arrows: 'to',
            color: { 
              color: person.activeDataPerson ? '#2e4a71' : '#9e9e9e', 
              highlight: person.activeDataPerson ? '#2e4a71' : '#9e9e9e' 
            }
          });
        });
      }

      setData({ nodes, links });

      // Fetch and process sector data
      const sectorsResponse = await axios.get(SECTOR_API.getAllSectors, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sectorData = sectorsResponse.data || [];
      setSectors(sectorData);

      // Process sector information
      inferCompanySector(companyData, sectorData);

    } catch (error) {
      console.error("Error fetching data", error);
      addNotification('Error fetching company details', 'error');
    }
  };
  

  const inferCompanySector = (companyData, sectorData) => {
    console.log("Company Data:", companyData);
    console.log("Available Sectors:", sectorData);

    if (!companyData || !Array.isArray(companyData.purposes) || companyData.purposes.length === 0) {
        setSectorInfo("Sector information is not available.");
        return;
    }

    if (!sectorData || !Array.isArray(sectorData.data)) {
        setSectorInfo("Sector data is not available or not formatted correctly.");
        return;
    }

    const sectorMap = new Map(sectorData.data.map(sector => [sector.id, sector.name]));
    const inferredSectorData = [];
    const sectorIds = new Set();
    const duplicates = new Set();

    companyData.purposes.forEach(purpose => {
        if (purpose.sectorId) {
            const sectorName = sectorMap.get(purpose.sectorId);
            if (sectorName) {
                const sectorInfo = {
                    id: purpose.sectorId,
                    name: sectorName,
                    activeDataSector: purpose.activeDataPurpose
                };

                if (sectorIds.has(sectorName)) {
                    console.warn(`Found duplicate sector: ${sectorName}`);
                    duplicates.add(sectorName);
                } else {
                    console.log(`Found sector: ${sectorName} for purpose: ${purpose.purpose}`);
                    sectorIds.add(sectorName);
                    inferredSectorData.push(sectorInfo);
                }
            } else {
                console.warn(`No matching sector found for sectorId: ${purpose.sectorId}`);
            }
        }
    });

    setInferredSectors(inferredSectorData);
    
    const sectorNames = Array.from(sectorIds);
    console.log("Sector names:", sectorNames);
    if (duplicates.size > 0) {
        console.log("Duplicate sectors found:", Array.from(duplicates).join(", "));
    }
    
    setSectorInfo(sectorNames.length > 0 ? sectorNames.join(", ") : "No sector info available");
};


  

  useEffect(() => {
    getDetails();
  }, [id]);

  useEffect(() => {
    const fetchCompanyHistory = async () => {
      try {
        const response = await COMPANY_API.getCompanyHistory(id);
        if (response?.data) {
          console.log('Company History Data:', response.data);
          setCompanyHistory(response.data);
        }
      } catch (error) {
        console.error('Error fetching company history:', error);
        addNotification('Failed to fetch company history', 'error');
      }
    };

    if (id) {
      fetchCompanyHistory();
    }
  }, [id]);

  useEffect(() => {
    if (details && details.persons) {
      const nodes = [];
      const links = [];
      const usedPersonIds = new Set();

      // Add company node
      nodes.push({
        id: `company-${details.genUniqueId}`,
        label: details.companyName,
        group: 'company',
        type: 'company'
      });

      // Filter and add only active persons
      details.persons
        .filter(person => person.activeDataPerson === true)
        .forEach(person => {
          let personNodeId = `person-${person.id || person.personalData}`;
          let counter = 1;
          while (usedPersonIds.has(personNodeId)) {
            personNodeId = `person-${person.id || person.personalData}-${counter}`;
            counter++;
          }
          usedPersonIds.add(personNodeId);

          nodes.push({
            id: personNodeId,
            label: person.personalData || 'Unknown Person',
            group: 'person',
            type: 'person',
            originalId: person.id,
            personalData: person.personalData,
            uniqueId: person.uniqueId,
            function: person.function
          });
          
          links.push({
            from: personNodeId,
            to: `company-${details.genUniqueId}`,
            arrows: 'to',
            color: { color: '#2e4a71', highlight: '#2e4a71' }
          });
      });

      setData({ nodes, links });
    }
  }, [details]);

  useEffect(() => {
    if (data.nodes.length && data.links.length) {
      try {
        const nodes = new DataSet(data.nodes.map(node => {
          return {
            ...node,
            shape: 'icon',
            icon: {
              face: 'FontAwesome',
              code: node.type === 'person' ? '\uf007' : '\uf1ad',
              size: 50,
              color: node.type === 'person' ? '#4CAF50' : '#2e4a71'
            },
            font: {
              size: node.type === 'person' ? 10 : 14,
              color: '#424242',
              face: 'Roboto',
              strokeWidth: 2,
              strokeColor: '#ffffff',
              multi: true,
              bold: {
                color: node.type === 'person' ? '#4CAF50' : '#2e4a71',
                size: node.type === 'person' ? 10 : 14,
                mod: 'bold'
              },
              vadjust: node.type === 'person' ? -8 : -10
            },
            size: 30,
            borderWidth: 2,
            borderWidthSelected: 3,
            color: {
              border: node.type === 'person' ? '#4CAF50' : '#2e4a71',
              background: '#ffffff',
              highlight: {
                border: node.type === 'person' ? '#4CAF50' : '#2e4a71',
                background: '#e8eef7'
              }
            }
          };
        }));
        const edges = new DataSet(data.links);

        networkInstance = new Network(containerRef.current, { nodes, edges }, {
          nodes: {
            shape: 'icon',
            icon: {
              face: 'FontAwesome',
              code: '\uf007',
              size: 50,
              color: '#2e4a71'
            },
            font: {
              size: 10,
              color: '#424242',
              face: 'Roboto',
              strokeWidth: 2,
              strokeColor: '#ffffff',
              multi: true,
              bold: {
                color: '#2e4a71',
                size: 10,
                mod: 'bold'
              },
              vadjust: -8
            },
            size: 30,
            borderWidth: 2,
            borderWidthSelected: 3,
            color: {
              border: '#2e4a71',
              background: '#ffffff',
              highlight: {
                border: '#2e4a71',
                background: '#e8eef7'
              }
            }
          },
          edges: {
            arrows: {
              to: {
                enabled: true,
                scaleFactor: 0.5,
                type: 'arrow'
              }
            },
            color: {
              color: '#2e4a71',
              highlight: '#2e4a71'
            },
            smooth: {
              enabled: true,
              type: 'continuous'
            }
          },
          interaction: {
            dragNodes: true,
            dragView: true,
            hover: true,
            hoverConnectedEdges: true,
            selectable: true,
            selectConnectedEdges: true,
            tooltipDelay: 300,
            zoomView: true,
            navigationButtons: true,
            keyboard: true,
            multiselect: false
          },
          physics: {
            enabled: true,
            barnesHut: {
              gravitationalConstant: -2000,
              centralGravity: 0.3,
              springLength: 200,
              springConstant: 0.04,
              damping: 0.09
            },
            stabilization: {
              enabled: true,
              iterations: 1000,
              updateInterval: 100,
              onlyDynamicEdges: false,
              fit: true
            }
          }
        });

        // Add click handler
        networkInstance.on("click", function(params) {
          if (params.nodes.length > 0) {
            const nodeId = params.nodes[0];
            const clickedNode = nodes.get(nodeId);
            if (clickedNode && clickedNode.type === 'person') {
              handleNodeClick(nodeId);
            } else if (clickedNode && clickedNode.type === 'company') {
              handleNodeClick(nodeId);
            }
          }
        });

        // Add hover effects
        networkInstance.on("hoverNode", function(params) {
          const container = containerRef.current;
          if (container) {
            container.style.cursor = 'pointer';
          }
        });

        networkInstance.on("blurNode", function(params) {
          const container = containerRef.current;
          if (container) {
            container.style.cursor = 'default';
          }
        });

      } catch (error) {
        console.error('Error creating network:', error);
      }
    }

    return () => {
      if (networkInstance) {
        networkInstance.destroy();
      }
    };
  }, [data]);

  const handleNodeClick = async (selectedNodeId) => {
    console.log("Selected node id", selectedNodeId);
    try {
      // Get the node data
      const nodes = networkInstance.body.data.nodes;
      const selectedNode = nodes.get(selectedNodeId);
      console.log("Selected node data:", selectedNode);
      
      const token = localStorage.getItem("TOKEN");
      if (!token) {
        addNotification('Authentication token not found', 'error');
        return;
      }

      if (selectedNode.type === 'person') {
        // Handle person node click
        const ownerId = selectedNode.personalData || selectedNode.originalId || selectedNode.id.split('-')[1];
        console.log("Using owner identifier:", ownerId);

        const response = await axios.get(`https://api.kseco.eu/api/companies/by-owner/${encodeURIComponent(ownerId)}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        
        console.log("API Response:", response.data);
    
        if (response.data.success && response.data.data.length > 0) {
          const companies = response.data.data;
          const existingNodes = new Set(nodes.getIds().map(id => id.toString()));
          const newCompanies = companies.filter(company => !existingNodes.has(`company-${company.genUniqueId}`));
          
          if (newCompanies.length > 0) {
            updateNetworkWithNewCompanies(newCompanies, selectedNodeId);
            addNotification(`Found ${newCompanies.length} new companies`, 'success');
          } else {
            addNotification('All related companies are already shown', 'info');
          }
        } else {
          addNotification('No additional companies found for this owner', 'info');
        }
      } else if (selectedNode.type === 'company') {
        console.log("Full node data:", selectedNode);
        
        // Get the genUniqueId from the node
        const companyId = selectedNode.genUniqueId;
        console.log("Using company genUniqueId:", companyId);
        
        if (!companyId) {
          addNotification('Company ID not found', 'error');
          return;
        }
        
        // Get token and verify it exists
        const token = localStorage.getItem('TOKEN');
        if (!token) {
          addNotification('Authentication token not found. Please log in again.', 'error');
          return;
        }

        try {
          // Use the correct endpoint for getting company owners
          const response = await axios.get(`https://api.kseco.eu/api/companies/${companyId}/owners`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            }
          });

          console.log("Company owners response:", response.data);

          if (response.data && response.data.data && Array.isArray(response.data.data)) {
            const persons = response.data.data.filter(person => person.activeDataPerson === true);
            const existingNodes = new Set(nodes.getIds().map(id => id.toString()));
            const newPersons = persons.filter(person => !existingNodes.has(`person-${person.id}`));

            if (newPersons.length > 0) {
              updateNetworkWithNewPersons(newPersons, selectedNodeId);
              addNotification(`Found ${newPersons.length} active owners for this company`, 'success');
            } else {
              addNotification('All owners are already shown', 'info');
            }
          } else {
            addNotification('No active owners found for this company', 'info');
          }
        } catch (error) {
          console.error('Failed to fetch company owners:', error);
          if (error.response?.status === 401) {
            addNotification('Session expired. Please log in again.', 'error');
          } else if (error.response?.status === 404) {
            addNotification('Company not found. Please try again.', 'error');
          } else {
            addNotification('Error fetching company owners: ' + error.message, 'error');
          }
        }
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      if (error.response?.status === 401) {
        addNotification('Authentication error. Please log in again.', 'error');
      } else if (error.response?.status === 500) {
        addNotification('Server error. Please try again later.', 'error');
      } else {
        addNotification('Error fetching data: ' + error.message, 'error');
      }
    }
  };

  const updateNetworkWithNewCompanies = (companies, parentId) => {
    const newNodes = companies.map(company => ({
      id: `company-${company.genUniqueId}`,
      label: company.companyName,
      group: 'company',
      type: 'company',
      genUniqueId: company.genUniqueId  // Store genUniqueId for API calls
    }));

    const newEdges = companies.map(company => ({
      from: parentId,
      to: `company-${company.genUniqueId}`,
      arrows: 'to',
      color: { color: '#2e4a71', highlight: '#2e4a71' }
    }));

    setData(prevData => ({
      nodes: [...prevData.nodes, ...newNodes],
      links: [...prevData.links, ...newEdges]
    }));
  };

  const updateNetworkWithNewPersons = (persons, parentId) => {
    // Filter for active persons only
    const activePersons = persons.filter(person => person.activeDataPerson === true);
    console.log("Active persons:", activePersons);

    if (activePersons.length === 0) {
      addNotification('No active owners found', 'info');
      return;
    }

    // Create nodes for each active person
    const newNodes = activePersons.map(person => ({
      id: `person-${person.id || person.personalData}`,
      label: person.personalData || 'Unknown Person',
      group: 'person',
      type: 'person',
      personalData: person.personalData,
      activeDataPerson: person.activeDataPerson
    }));

    // Create edges connecting persons to the company
    const newEdges = activePersons.map(person => ({
      id: `${parentId}-${person.id || person.personalData}`,
      from: parentId,
      to: `person-${person.id || person.personalData}`
    }));

    try {
      // Get current nodes and edges
      const currentNodes = networkInstance.body.data.nodes;
      const currentEdges = networkInstance.body.data.edges;

      // Filter out any duplicate nodes
      const uniqueNewNodes = newNodes.filter(newNode => 
        !currentNodes.get().some(existingNode => 
          existingNode.id === newNode.id && 
          existingNode.type === 'person' && 
          existingNode.activeDataPerson === true
        )
      );

      // Filter out any duplicate edges
      const uniqueNewEdges = newEdges.filter(newEdge => 
        !currentEdges.get().some(existingEdge => 
          existingEdge.from === newEdge.from && 
          existingEdge.to === newEdge.to
        )
      );

      console.log("Adding unique nodes:", uniqueNewNodes);
      console.log("Adding unique edges:", uniqueNewEdges);

      // Add the unique nodes and edges
      if (uniqueNewNodes.length > 0) {
        networkInstance.body.data.nodes.add(uniqueNewNodes);
      }
      if (uniqueNewEdges.length > 0) {
        networkInstance.body.data.edges.add(uniqueNewEdges);
      }

      // Update the notification message
      if (uniqueNewNodes.length > 0) {
        addNotification(`Added ${uniqueNewNodes.length} new active owners`, 'success');
      } else {
        addNotification('All active owners are already shown', 'info');
      }
    } catch (error) {
      console.error("Error updating network:", error);
      addNotification('Error adding owners to the network', 'error');
    }
  };
  
  
  console.log(details.dateOfActs);

  const toggleActiveDateId = (personId) => {
    console.log(`Toggling active state for person with ID: ${personId}`);
  
    setDetails(prevDetails => ({
      ...prevDetails,
      persons: prevDetails.persons.map(item => ({
        ...item,
        activeDataPerson: item.id === personId ? !item.activeDataPerson : item.activeDataPerson,
      })),
    }));
  };
  
  const handleGeneratePDF = async () => {
    try {
      // First get the historical data
      const historyResponse = await COMPANY_API.getCompanyHistory(id);
      const historyData = historyResponse?.data || [];

      // Get CHE number from details
      const cheNumber = details.che || details.CHE || details.ca;
      
      if (!cheNumber) {
        console.error('No CHE number found in details:', details);
        addNotification('error', 'Error: Company CHE number not found');
        return;
      }

      // Ensure proper data structure for PDF generation
      const pdfData = {
        current: {
          ...currentCompany,
          companyName: currentCompany.name || currentCompany.companyName,
          ca: cheNumber,
          dateOfRegistration: currentCompany.dateOfRegistration || currentCompany.date,
          city: currentCompany.city,
          companyType: currentCompany.companyType
        },
        history: historyData.map(hist => ({
          ...hist,
          companyName: hist.name || hist.companyName,
          ca: hist.ca || hist.CHE,
          date: hist.dateOfRegistration || hist.date,
          city: hist.city,
          companyType: hist.companyType
        })),
        companyBasicInfo: {
          ...details,
          companyNo: cheNumber,
          legalForm: currentCompany.companyType,
          registeredDate: currentCompany.dateOfRegistration,
          transferCH: currentCompany.transferCH
        }
      };

      console.log('Generating PDF with data:', { cheNumber, pdfData });
      await generatePDF(pdfData, sectors);
      addNotification('success', 'PDF generated successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
      addNotification('error', 'Error generating PDF');
    }
  };

  const handlePDFWithHistory = handleGeneratePDF;

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="water">
          <div className="row all printme" id="pdf">
            <div className="col-lg-12">
              <div className="card text-center"></div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="card header-card">
                    <div className="card-title-container">
                      <i className="bi bi-building"></i>
                      <h1 className={`company-name ${!company.activeData ? 'stike' : ''}`}>
                        {company.companyName} 
                      </h1>
                    </div>
                    <div className="company-info-grid">
                      <div className="info-item">
                        <span className="info-label">Unique ID</span>
                        <span className="info-value">{company.che}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Date of registration</span>
                        <span className="info-value">
                          {company.dateOfRegistration 
                            ? new Date(company.dateOfRegistration).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              })
                            : t('details.notAvailable')
                          }
                        </span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Date of publication</span>
                        <span className="info-value">
                          {company.zefixDate 
                            ? new Date(company.zefixDate).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              })
                            : t('details.notAvailable')
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card pdf-button-card">
                    <button
                      className="pdf no-printme"
                      onClick={handleGeneratePDF}
                      title="Generate PDF with History"
                    >
                       <i className="bi bi-file-earmark-pdf"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col">{t('details.tables.business.headers.in')}</th>
                            <th scope="col">
                              <i
                                className="bi bi-building deti"
                                style={{ marginBottom: '-5px' }}
                              ></i>
                              &nbsp;{t('details.tables.business.headers.businessName')}
                            </th>
                            <th scope="col">{t('details.tables.business.headers.legalForm')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ borderBottom: '1px solid #dee2e6' }}>
                            <th scope="row">{company.inCompany}</th>
                            <td>
                              <p className={!company.activeData ? 'stike' : ''}>
                                {company.companyName}
                              </p>
                            </td>
                            <td>{company.companyType}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                
                <div className="col-sm-3" style={{ marginTop: '10px' }}>
                  <div className="card">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">{t('details.tables.business.headers.in')}</th>
                          <th scope="col">
                            <i
                              className="bi bi-house deti "
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;{t('details.tables.legalSeat.title')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
    {(details?.cities || []).length > 0 ? (
      details.cities.map((item, index) => (
        <tr
          style={{ borderBottom: '1px solid #dee2e6' }}
          key={item.uniqueId}
          className={item.activeDataCity ? 'active-row' : 'inactive-row'} // Conditional class for styling
        >
          <th scope="row">{index + 1}</th>
          <td>
            <p className={item.activeDataCity ? '' : 'stike'}>
              {item.city}
            </p>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="2">{t('details.tables.legalSeat.noData')}</td>
      </tr>
    )}
  </tbody>
                    </table>
                  </div>
                </div>
              </div>

                <div className="row">
                <div className="col-sm-5" style={{ marginTop: '20px' }}>
  <div className="card">
    <div className="card-body">
      <table className="table detaj">
        <thead>
          <tr>
            <th scope="col">{t('details.tables.business.headers.in')}</th>
            <th scope="col">
              <i className="bi bi-geo deti" style={{ marginBottom: '-5px' }}></i>
              &nbsp;{t('details.tables.address.title')}
            </th>
          </tr>
        </thead>
        <tbody>
          {(details?.addresses || []).length > 0 ? (
            (details?.addresses || []).map((item, index) => (
              <tr
                style={{ borderBottom: '1px solid #dee2e6' }}
                key={`${item.uniqueId}-${index}`}
                className={item.activeDataAddress ? 'active-row' : 'inactive-row'}
              >
                <th scope="row">{index + 1}</th>
                <td>
                  <p className={item.activeDataAddress ? '' : 'stike'}>
                    {item.address}
                  </p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">{t('details.tables.address.noData')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>


  <div className="col-sm-3" style={{ marginTop: '20px' }}>
    <div className="card">
      <div className="card-body">
        <table className="table detaj">
          <thead>
            <tr>
              <th scope="col">{t('details.tables.business.headers.in')}</th>
              <th scope="col">
                <i className="bi bi-journal-check deti"></i>&nbsp;{t('details.tables.dateOfActs.title')}
              </th>
            </tr>
          </thead>
          <tbody>
            {(details?.dateOfActs || []).length > 0 ? (
              [...new Map(details.dateOfActs.map(item => [item.dateOfAct, item])).values()].map((item, index) => (
                <tr
                  style={{ borderBottom: '1px solid #dee2e6' }}
                  key={`${item.id}-${item.dateOfAct}`}
                  onClick={() => toggleActiveDate(item)}
                >
                  <th scope="row">{index + 1}</th>
                  <td className={!item.activeDataDateOfAct ? 'stike' : ''} 
                      style={{ color: activeDateId === item.id ? 'blue' : 'black' }}>
                    {item.dateOfAct}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">{t('details.tables.dateOfActs.noData')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>

  {/* New Sector Table */}
  <div className="col-sm-4" style={{ marginTop: '20px' }}>
  <div className="card">
    <div className="card-body">
      <table className="table detaj">
        <thead>
          <tr>
            <th scope="col">{t('details.tables.sectors.sectorId')}</th>
            <th scope="col">
              <i className="bi bi-cpu"></i> {t('details.tables.sectors.title')}
            </th>
          </tr>
        </thead>
        <tbody>
          {inferredSectors.length > 0 ? (
            inferredSectors.map((sector, index) => (
              <tr
                key={sector.id}
                className={sector.activeDataSector ? 'active-row' : 'inactive-row'}
              >
                <th scope="row">{sector.id}</th>
                <td>
                  <p className={sector.activeDataSector ? '' : 'stike'}>
                    {sector.name}
                  </p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">{t('details.tables.sectors.noData')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>

</div>



              

<div className="row">

<div className="col-sm-4">
        <div className="card">
          <div className="card-body">
            <table className="table detaj">
              <thead>
                <tr>
                  <th scope="col">{t('details.tables.shares.headers.in')}</th>
                  <th scope="col">
                    <i className="bi bi-coin"></i>&nbsp;{t('details.tables.shares.headers.shares')}
                  </th>
                  <th scope="col">
                    <i className="bi bi-person"></i>&nbsp;{t('details.tables.shares.headers.partner')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {details?.shares?.length > 0 ? (
                  details.shares.map((item, index) => (
                    <tr
                      style={{ borderBottom: '1px solid #dee2e6' }}
                      key={`${item.uniqueId}-${index}`}
                      className={item.activeDataShares ? 'active-row' : 'inactive-row'}
                    >
                      <th scope="row">{index + 1}</th>
                      <td>
                        <p className={item.activeDataShares ? '' : 'stike'}>
                          {item.shares}
                        </p>
                      </td>
                      <td>
                        <p className={item.activeDataShares ? '' : 'stike'}>
                          {item.partner}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">{t('details.tables.shares.noData')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Capital Table */}
      <div className="col-sm-4">
        <div className="card">
          <div className="card-body">
            <table className="table detaj">
              <thead>
                <tr>
                  <th scope="col">{t('details.tables.business.headers.in')}</th>
                  <th scope="col">
                    <i className="bi bi-currency-euro"></i>&nbsp;{t('details.tables.capital.title')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {details?.capital && (
                  Array.isArray(details.capital) ? (
                    details.capital.map((item, index) => (
                      <tr
                        style={{ borderBottom: '1px solid #dee2e6' }}
                        key={`${item.uniqueId}-${index}`}
                        className={item.activeDataCapital ? 'active-row' : 'inactive-row'}
                      >
                        <th scope="row">{index + 1}</th>
                        <td>
                          <p className={item.activeDataCapital ? '' : 'stike'}>
                            {item.capital}
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      style={{ borderBottom: '1px solid #dee2e6' }}
                      className={details.capital.activeDataCapital ? 'active-row' : 'inactive-row'}
                    >
                      <th scope="row">1</th>
                      <td>
                        <p className={details.capital.activeDataCapital ? '' : 'stike'}>
                          {details.capital.capital}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Registration Offices Table */}
      <div className="col-sm-4">
        <div className="card">
          <div className="card-body">
            <table className="table detaj">
              <thead>
                <tr>
                  <th scope="col">{t('details.tables.business.headers.in')}</th>
                  <th scope="col">
                    <i className="bi bi-briefcase deti"></i>&nbsp;{t('details.tables.registrationOffice.title')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {details?.registrationOffice && (
                  Array.isArray(details.registrationOffice) ? (
                    // Handle array of registration offices
                    details.registrationOffice.map((item, index) => (
                      <tr
                        style={{ borderBottom: '1px solid #dee2e6' }}
                        key={item.id || `registration-${index}`}
                        className={item.activeDataRegistration ? 'active-row' : 'inactive-row'}
                      >
                        <th scope="row">{index + 1}</th>
                        <td>
                          <p className={item.activeDataRegistration ? '' : 'stike'}>
                            {item.officialPublication || item.registrationOffice || 'N/A'}
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Handle single registration office object
                    <tr
                      style={{ borderBottom: '1px solid #dee2e6' }}
                      key={details.registrationOffice.id || 'single-registration'}
                      className={details.registrationOffice.activeDataRegistration ? 'active-row' : 'inactive-row'}
                    >
                      <th scope="row">1</th>
                      <td>
                        <p className={details.registrationOffice.activeDataRegistration ? '' : 'stike'}>
                          {details.registrationOffice.officialPublication || 
                           details.registrationOffice.registrationOffice || 
                           'N/A'}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

</div>


<div className="row">
  <div className="col-sm-12">
    <div className="card">
      <div className="card-body">
        <table className="table detaj">
          <thead>
            <tr>
              <th scope="col">{t('details.tables.purpose.headers.in')}</th>
              <th scope="col">{t('details.tables.purpose.headers.purpose')}</th>
              <th scope="col">{t('details.tables.purpose.headers.sector')}</th>
              <th scope="col">{t('details.tables.purpose.headers.service')}</th>
            </tr>
          </thead>
          <tbody>
            {(details?.purposes || []).map((item, index) => {
              const sectorName = sectors?.data?.find(s => s.id === item.sectorId)?.name;
              return (
                <tr
                  style={{ borderBottom: '1px solid #dee2e6' }}
                  key={item.id || index}
                  className={item.activeDataPurpose ? 'active-row' : 'inactive-row'}
                >
                  <th scope="row">{index + 1}</th>
                  <td>
                    <p className={item.activeDataPurpose ? '' : 'strike'}>
                      {item.purpose || item.service?.name || 'N/A'}
                    </p>
                  </td>
                  <td>
                    <p className={item.activeDataPurpose ? '' : 'strike'}>
                      {sectorName || 'N/A'}
                    </p>
                  </td>
                  <td>
                    <small>
                      {item.serviceId && `Service: ${item.serviceId}`}
                    </small>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>



              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col" className="in-column">{t('details.tables.remarks.headers.in')}</th>
                            <th scope="col" className="remarks-column">
                              <i className="bi bi-chat-text"></i>&nbsp;{t('details.tables.remarks.title')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
            {details?.remarks?.length > 0 ? (
              details.remarks.map((item, index) => (
                <tr
                  style={{ borderBottom: '1px solid #dee2e6' }}
                  key={`${item.id}-${index}`}
                  className={item.activeDataRemark ? 'active-row' : 'inactive-row'}
                >
                  <th scope="row" className="in-column">{index + 1}</th>
                  <td className="remarks-column">
                    <p className={item.activeDataRemark ? '' : 'stike'}>
                      {item.remark}
                    </p>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">{t('details.tables.remarks.noData')}</td>
                </tr>
              )}
          </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* Publications Table */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table detaj">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: '5%', textAlign: 'center' }}>{t('details.tables.publications.headers.in')}</th>
                            <th scope="col" style={{ width: '25%', textAlign: 'left' }}>
                              <i
                                className="bi bi-journal-text deti"
                                style={{ marginBottom: '-5px', marginRight: '5px' }}
                              ></i>
                              {t('details.tables.publications.headers.journalDate')}
                            </th>
                            <th scope="col" style={{ width: '25%', textAlign: 'left' }}>{t('details.tables.publications.headers.sogcDate')}</th>
                            <th scope="col" style={{ width: '22.5%', textAlign: 'left' }}>{t('details.tables.publications.headers.journalNumber')}</th>
                            <th scope="col" style={{ width: '22.5%', textAlign: 'left' }}>{t('details.tables.publications.headers.sogcNumber')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details?.publications?.length > 0 ? (
                            details.publications.map((item, index) => (
                              <tr
                                style={{ borderBottom: '1px solid #dee2e6' }}
                                key={`publication-${item.id}-${index}`}
                                className="active-row"
                              >
                                <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                <td style={{ textAlign: 'left' }}>
                                  {new Date(item.journalDate).toLocaleDateString()}
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                  {new Date(item.SOGCDate).toLocaleDateString()}
                                </td>
                                <td style={{ textAlign: 'left' }}>{item.journalNumber?.trim()}</td>
                                <td style={{ textAlign: 'left' }}>{item.SOGCNumber?.trim()}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" style={{ textAlign: 'center' }}>{t('details.tables.publications.noData')}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <table className="table detaj">
                      <thead>
                        <tr>
                          <th scope="col">{t('details.tables.persons.headers.in')}</th>
                          <th scope="col">{t('details.tables.persons.headers.personalData')}</th>
                          <th scope="col">{t('details.tables.persons.headers.function')}</th>
                          <th scope="col">
                            <i
                              className="bi bi-pencil deti"
                              style={{ marginBottom: '-5px' }}
                            ></i>
                            &nbsp;{t('details.tables.persons.headers.signature')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
  {(details?.persons || []).map((item, index) => (
    <tr
      style={{ borderBottom: '1px solid #dee2e6' }}
      key={`${item.uniqueId}-${index}`} // Use a unique key
      className={item.activeDataPerson ? 'active-row' : 'inactive-row'} // Conditional class for styling
      onClick={() => toggleActiveDateId(item.uniqueId)} // Pass uniqueId or relevant identifier
    >
      <th scope="row">{index + 1}</th>
      <td>
        <p className={!item.activeDataPerson ? 'stike' : ''}>
          {item.personalData}
        </p>
      </td>
      <td>{item.function}</td>
      <td>{item.signature}</td>
    </tr>
  ))}
</tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="network-container">
          <div className="network-legend">
            <div className="legend-item">
              <User2 className="legend-icon legend-person" />
              <span>{t('details.network.legend.person')}</span>
            </div>
            <div className="legend-item">
              <Building2 className="legend-icon legend-company" />
              <span>{t('details.network.legend.company')}</span>
            </div>
            <div className="legend-item">
              <NetworkIcon className="legend-icon" style={{ color: '#9E9E9E' }} />
              <span>{t('details.network.legend.connection')}</span>
            </div>
          </div>
          <div ref={containerRef} style={{ width: '100%', height: '600px'}} />
          {data.nodes.length === 0 && data.links.length === 0 && (
            <div style={{ textAlign: 'center', padding: '20px', color: '#757575' }}>
              <NetworkIcon size={40} />
              <p>{t('details.network.loading')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
