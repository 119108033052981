/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import axios from "axios";
import Navbar from "../Navbar2/navbar2";
import "./dashboard.css";
import CountUp from "react-countup";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import * as bot from "react-bootstrap";
import { FilterList, DateRangeFilter, RangeFilter, DateFilter, GenderFilter, MunicipalityFilter, OfficialPublicationFilter } from "./FilterList";
import Pagination from "react-js-pagination";
import ApexChart from "./Chart";
import Bar from "./Bar";
import a from "../../images/excel (1).png";
import Publication from "./Publication";
import Sidebar from "../Shop/Cart/Sidebar";

import NationalityFilterDashboard from "./NationalitiFilterDashboard";
import { 
  ModernFilterList, 
  ModernRangeFilter, 
  ModernDateFilter, 
  ModernMunicipalityFilter, 
  ModernOfficialPublicationFilter, 
  ModernPublicationDateFilter, 
  ModernPublicationRangeFilter, 
  ModernPublicationFilter, 
  ModernServiceFilter,
  ModernCityFilter,
  ModernZefixDateRangeFilter
} from './ModernFilters.tsx';
import { useTranslation } from 'react-i18next';

const PublicationTypes = {
  TodayPublication: "Today Publications",
  AllAlbanian: "All Albanian Publications",
  AllPublications: "All Publications",
};

const BASE_URL = 'https://api.kseco.eu/api';

function Dashboard() {  
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    gender: [],
    municipality: "",
    officialPublication: [],
    publicationDate: null,
    publicationDateRange: { from: null, to: null },
    zefixDateRange: { from: null, to: null },
    canton: [],
    companyType: [],
    nationality: [],
    purposes: [],
    serviceName: "",
    city: "",
    date: "", 
    publication: "", 
    searchTerm: ""
  });

  const invalidateCache = useCallback(() => {
    const cacheKeys = [
      'services',
      'nationalities',
      'companyTypes',
      'purposes',
      'statistics',
      'municipalities'
    ];
    cacheKeys.forEach(key => sessionStorage.removeItem(key));
  }, []);

  const invalidateCompaniesCache = useCallback(() => {
    Object.keys(sessionStorage).forEach(key => {
      if (key.startsWith('companies_')) {
        sessionStorage.removeItem(key);
      }
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  

  const [debouncedFilters, setDebouncedFilters] = useState(filters);

  const [debouncedSearch, setDebouncedSearch] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      if (filters.searchTerm !== undefined) {
        setDebouncedSearch(filters.searchTerm);
      }
    }, 300); // 300ms debounce delay for search

    return () => clearTimeout(handler);
  }, [filters.searchTerm]);

  useEffect(() => {
    const currentFilters = { ...filters };
  
    // Only use the debounced search value for API calls
    if (debouncedSearch !== undefined) {
      currentFilters.searchTerm = debouncedSearch;
    }
  
    setDebouncedFilters(currentFilters);
    getCompanies(currentPage);
  }, [
    debouncedSearch, 
    filters.publication, 
    filters.companyType, 
    filters.canton, 
    filters.city,
    filters.gender,
    filters.municipality,
    filters.officialPublication,
    filters.publicationDate,
    filters.publicationDateRange,
    filters.zefixDateRange,
    filters.nationality,
    filters.purposes,
    currentPage
  ]);

  const [filtersApplied, setFiltersApplied] = useState(false);

  const [filterData, setFilterData] = useState({
    genders: [
        { id: '1', name: 'Male' },    // Verify these IDs match your database
        { id: '2', name: 'Female' }    // Verify these IDs match your database
    ]
  });

  // useEffect(() => {
  //   // Fetch municipalities and other filter data from API
  //   const fetchFilterData = async () => {
  //     try {
  //       const token = localStorage.getItem("TOKEN");
  //       const response = await axios.get('/api/municipalities', {
  //         headers: { Authorization: `Bearer ${token}` }
  //       });

  //       console.log("municipality data:", response.data.map(m => ({ id: m.id, name: m.name })));
  //       setFilterData(prev => ({
  //         ...prev,
  //         municipalities: response.data.map(m => ({ id: m.id, name: m.name }))
  //       }));
  //     } catch (error) {
  //       console.error("Error fetching filter data:", error);
  //     }
  //   };

  //   fetchFilterData();
  // }, []);




  const getCompanies = useCallback(async (page) => {
    const cacheKey = `companies_${page}_${JSON.stringify(debouncedFilters)}`;
    const cached = sessionStorage.getItem(cacheKey);
    
    if (cached && !filtersApplied) {
      const parsedData = JSON.parse(cached);
      setCompanies(parsedData.data);
      setTotalData(parsedData.meta.total);
      return;
    }
  
    setLoading(true);
  
    try {
      const token = localStorage.getItem("TOKEN");
      const baseEndpoint = `${BASE_URL}/companies`;
        
      // Build query parameters
      const queryParams = new URLSearchParams();
      queryParams.append('page', page);
      queryParams.append('size', 10);
  
      if (debouncedFilters) {
        const cleanedFilters = {};
          
        // Handle search term
        if (debouncedFilters.searchTerm) {
          queryParams.append('search', debouncedFilters.searchTerm);
        }
  
        // Handle canton filter
        if (debouncedFilters.canton?.length) {
          queryParams.append('canton', debouncedFilters.canton.join(','));
        }
  
        // Handle company type filter
        if (debouncedFilters.companyType?.length) {
          queryParams.append('companyType', debouncedFilters.companyType.join(','));
        }
  
        // Handle nationality filter
        if (debouncedFilters.nationality?.length) {
          queryParams.append('nationality', debouncedFilters.nationality.join(','));
        }
  
        // Handle purposes filter
        if (debouncedFilters.purposes?.length) {
          queryParams.append('purposes', debouncedFilters.purposes.join(','));
        }
  
        // Handle services filter
        if (debouncedFilters.serviceName) {
          queryParams.append('serviceName', debouncedFilters.serviceName);
        }
  
        // Handle publication filter
        if (debouncedFilters.publication) {
          if (debouncedFilters.publication === "All Albanian Publications") {
            queryParams.append('publication', 'albanian');
          } else if (debouncedFilters.publication === "Today Publications") {
            queryParams.append('publication', 'today');
          }
        }
  
        // Handle capital filter
        if (debouncedFilters.capital) {
          if (typeof debouncedFilters.capital.min !== 'undefined') {
            queryParams.append('capital[min]', debouncedFilters.capital.min);
          }
          if (typeof debouncedFilters.capital.max !== 'undefined') {
            queryParams.append('capital[max]', debouncedFilters.capital.max);
          }
        }
  
        // Handle date range filter
        if (debouncedFilters.publicationDateRange) {
          if (debouncedFilters.publicationDateRange.from) {
            queryParams.append('publicationDateRange[from]', debouncedFilters.publicationDateRange.from);
          }
          if (debouncedFilters.publicationDateRange.to) {
            queryParams.append('publicationDateRange[to]', debouncedFilters.publicationDateRange.to);
          }
        }

        // Handle zefix date range filter
        if (debouncedFilters.zefixDateRange) {
          if (debouncedFilters.zefixDateRange.from) {
            queryParams.append('zefixDateRange[from]', debouncedFilters.zefixDateRange.from);
          }
          if (debouncedFilters.zefixDateRange.to) {
            queryParams.append('zefixDateRange[to]', debouncedFilters.zefixDateRange.to);
          }
        }
      }
  
      const endpoint = `${baseEndpoint}?${queryParams.toString()}`;
      console.log('Fetching companies with endpoint:', endpoint);
  
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
  
      setCompanies(response.data.data);
      setTotalData(response.data.meta.total);
        
      const dateRange = calculateDateRange(response.data.data);
      setDateRange(dateRange);
  
      // Cache the response
      sessionStorage.setItem(cacheKey, JSON.stringify(response.data));
  
    } catch (error) {
      console.error("Error fetching companies:", error);
      setCompanies([]);
    } finally {
      setLoading(false);
    }
  }, [debouncedFilters]);


  const handleFilterChange = useCallback((key, value) => {
    console.log(`Filter change - Key: ${key}, Value:`, value);
    
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      
      if (key === 'publication') {
        // Handle publication filter specially
        newFilters[key] = value === 'All Publications' ? '' : value;
      } else if (key === 'serviceName') {
        // Handle service search
        if (!value || value.trim() === '') {
          delete newFilters[key];
        } else {
          newFilters[key] = value.trim();
        }
      } else if (Array.isArray(value)) {
        // Handle array values (like checkboxes)
        if (value.length === 0) {
          delete newFilters[key];
        } else {
          newFilters[key] = value;
        }
      } else if (value === '' || value === undefined || value === null) {
        // Remove empty filters
        delete newFilters[key];
      } else {
        newFilters[key] = value;
      }
      
      return newFilters;
    });
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("TOKEN");
        const baseEndpoint = `${BASE_URL}/companies`;
        const params = { ...filters }; // Start with all filters
        
        // Handle publication filter
        if (params.publication) {
          if (params.publication === "All Albanian Publications") {
            params.publication = "albanian";
          } else if (params.publication === "Today Publications") {
            params.publication = "today";
          } else if (params.publication === "All Publications" || params.publication === "") {
            delete params.publication;
          }
        }

        // Handle service name
        if (params.serviceName && params.serviceName.trim()) {
          params.serviceName = params.serviceName.trim();
        } else {
          delete params.serviceName;
        }

        // Handle nationality exactly like Home component
        if (params.nationality && params.nationality.length > 0) {
          params.nationalityIds = params.nationality.join(',');
          delete params.nationality;
        }

        // Handle other array filters
        ['purposes', 'canton', 'companyType'].forEach(key => {
          if (params[key] && params[key].length > 0) {
            params[key] = params[key].join(',');
          } else {
            delete params[key];
          }
        });

        // Handle string filters
        if (params.city && params.city.trim()) {
          params.city = params.city.trim();
        } else {
          delete params.city;
        }

        // Add pagination
        params.page = currentPage;
        params.size = 10;

        console.log('Fetching companies with params:', params);
        const response = await axios.get(baseEndpoint, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        setCompanies(response.data.data);
        setTotalData(response.data.meta.total);
        const dateRange = calculateDateRange(response.data.data);
        setDateRange(dateRange);

      } catch (error) {
        console.error("Error fetching companies:", error);
        setCompanies([]);
      } finally {
        setLoading(false);
      }
    };

    // Debounce service search and regular search
    const handler = setTimeout(() => {
      fetchCompanies();
    }, (filters.searchTerm || filters.serviceName || filters.city) ? 300 : 0);

    return () => clearTimeout(handler);
  }, [filters, currentPage]);

  const [filterLocked, setFilterLocked] = useState(false);


  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const [countAll, setCountAll] = useState(null);
  const [countAl, setCountAl] = useState(null);
  const [countT, setCountT] = useState(null);
  const [countTAL, setCountTAL] = useState(null);


  const [cleanedFilter, setCleanedFilter] = useState({});

  // Function to update cleanedFilter based on the current filter state
  const updateCleanedFilter = () => {
    const newCleanedFilter = {};

    Object.keys(filters).forEach((key) => {
      if (key === 'municipality') {
        // Only include municipality if it has a non-empty value
        if (filters[key] && filters[key].trim() !== '') {
          newCleanedFilter[key] = filters[key];
        }
      }
      else if (key === 'capital') {
        if (filters[key] && (filters[key].min || filters[key].max)) {
          newCleanedFilter[key] = {
            min: parseFloat(filters[key].min) || 0,
            max: parseFloat(filters[key].max) || null
          };
        }
      } 
      // Special handling for arrays
      else if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) {
          newCleanedFilter[key] = filters[key];
        }
      } 
      // Special handling for objects (like ranges)
      else if (typeof filters[key] === 'object' && filters[key] !== null) {
        if (Object.keys(filters[key]).length > 0) {
          newCleanedFilter[key] = filters[key];
        }
      }
      // Regular values
      else if (filters[key]) {
        newCleanedFilter[key] = filters[key];
      }
    });

    setCleanedFilter(newCleanedFilter);
  };

  // Use useEffect to update cleanedFilter whenever filter changes
  useEffect(() => {
    updateCleanedFilter();
  }, [filters]);

  const publicationList = useMemo(
    () =>
      Object.keys(PublicationTypes).map((key) => ({
        name: PublicationTypes[key],
        id: key,
      })),
    []
  );

  function UnSelectAll() {
    var items = document.getElementsByClassName("checkbox");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") items[i].checked = false;
    }
  }

  const [totalData, setTotalData] = useState(0);
  const [companies, setCompanies] = useState([]);

  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const tableRef = useRef(null);
  const handleExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(companies);

    XLSX.utils.book_append_sheet(wb, ws, "Companies");

    XLSX.writeFile(wb, "Companies.xlsx");
  };
  const [selectedCheckBox, setSelectedCheckBox] = useState([]);


  const onCheckBoxClicked = (key, value) => {
    setFilterLocked(true);
    setFilters((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
    // setSelectedCheckBox({ ...selectedCheckBox, [key]: value });
  };
  

  const convertSectorFilter = (filter) => {
    const newFilter = { ...filter };
    
    // Handle gender filter
    if (Array.isArray(newFilter.gender) && newFilter.gender.length) {
      newFilter.gender = newFilter.gender;
    } else {
      delete newFilter.gender;
    }

    // Handle share value filter
    if (newFilter.shareValue && (newFilter.shareValue.min || newFilter.shareValue.max)) {
      newFilter['shareValue[min]'] = parseFloat(newFilter.shareValue.min) || 0;
      newFilter['shareValue[max]'] = parseFloat(newFilter.shareValue.max) || null;
      delete newFilter.shareValue;
    }

    // Existing filter conversions
    if (Array.isArray(newFilter.purposes) && newFilter.purposes.length) {
      newFilter.purposes = newFilter.purposes.join(',');
    } else {
      delete newFilter.purposes;
    }

    if (Array.isArray(newFilter.nationality) && newFilter.nationality.length) {
      // Keep it as an array
      newFilter.nationality = newFilter.nationality;
    } else {
      delete newFilter.nationality;
    }
    

    if (Array.isArray(newFilter.services) && newFilter.services.length) {
      newFilter.services = newFilter.services;
    } else {
      delete newFilter.services;
    }

    // Handle capital filter
    if (newFilter.capital) {
      if (typeof newFilter.capital.min !== 'undefined') {
        newFilter['capital[min]'] = parseFloat(newFilter.capital.min);
      }
      if (typeof newFilter.capital.max !== 'undefined') {
        newFilter['capital[max]'] = parseFloat(newFilter.capital.max);
      }
      delete newFilter.capital;
      console.log("Capital filter applied:", newFilter['capital[min]'], newFilter['capital[max]']);
    }

    console.log("Converted Filter:", newFilter); // Debugging line
    return newFilter;
  };
  
  
  
  
  

  useEffect(() => {
    console.log("search companies", searchTerm);
  }, [searchTerm]);




const fetchInitialData = useCallback(async () => {
  try {
    const token = localStorage.getItem("TOKEN");
    if (!token) {
      console.error('No authentication token found');
      return;
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    const getCachedOrFetch = async (key, fetchFn) => {
      const cached = sessionStorage.getItem(key);
      if (cached) {
        return JSON.parse(cached);
      }
      const result = await fetchFn();
      sessionStorage.setItem(key, JSON.stringify(result));
      return result;
    };

    setLoading(true);

    const [
      servicesData,
      nationalitiesData,
      companyTypesData,
      purposesData,
      cantonsData,
      statsData
    ] = await Promise.all([
      getCachedOrFetch('services', () => 
        axios.get(`${BASE_URL}/services`, { headers })),
      getCachedOrFetch('nationalities', () => 
        axios.get(`${BASE_URL}/nationalities`, { headers })),
      getCachedOrFetch('companytypes', () => 
        axios.get(`${BASE_URL}/companytypes`, { headers })),
      getCachedOrFetch('sectors', () => 
        axios.get(`${BASE_URL}/sectors`, { headers })),
      getCachedOrFetch('cantons', () => 
        axios.get(`${BASE_URL}/cantons`, { headers })),
      getCachedOrFetch('statistics', () => 
        axios.get(`${BASE_URL}/companies-statistics`, { headers }))
    ]);

    // Set all the data at once
    setService(servicesData.data.data || []);
    setNationality(nationalitiesData.data.data || []);
    setCompanyType(companyTypesData.data.data || []);
    setPurposes(purposesData.data.data.map(purpose => ({
      name: purpose.name,
      id: purpose.id.toString()
    })) || []);
    setCanton(cantonsData.data.data || []);

    // Set statistics
    if (statsData?.data?.data) {
      setCountAll(statsData.data.data.total_companies ?? 0);
      setCountAl(statsData.data.data.total_albanian_companies ?? 0);
      setCountTAL(statsData.data.data.total_today_albanian_companies ?? 0);
      setCountT(statsData.data.data.total_today_companies ?? 0);
    }

  } catch (error) {
    console.error("Error fetching initial data:", error);
    // Set empty arrays for all data states
    setService([]);
    setNationality([]);
    setCompanyType([]);
    setPurposes([]);
    setCanton([]);
    // Set zero for all counts
    setCountAll(0);
    setCountAl(0);
    setCountT(0);
    setCountTAL(0);
  } finally {
    setLoading(false);
  }
}, []);

// Replace all individual useEffects with a single one
useEffect(() => {
  fetchInitialData();
}, [fetchInitialData]);




  // useEffect(() => {
  //   if (!loading && debouncedFilters !== filters) {
  //     getCompanies(currentPage);
  //   }
  // }, [debouncedFilters, currentPage, getCompanies, loading, filters]);
  



  const calculateDateRange = (companiesList) => {
    if (!Array.isArray(companiesList) || companiesList.length === 0) {
      return { startDate: null, endDate: null };
    }
    
    // Filter out invalid dates and convert to Date objects
    const validDates = companiesList
      .map(company => {
        console.log('Company data:', company);
        return company.createdAt ? new Date(company.createdAt) : null;
      })
      .filter(date => date && !isNaN(date.getTime()));

    console.log('Valid dates found:', validDates);

    if (validDates.length === 0) {
      return { startDate: null, endDate: null };
    }

    const startDate = new Date(Math.min(...validDates));
    const endDate = new Date(Math.max(...validDates));

    return { startDate, endDate };
  };


  //commented this because it was causing reduntand filtering 1


  // useEffect(() => {
  //   if (filters.searchTerm || filters.canton || filters.companyType || filters.nationality || filters.purposes || filters.services) {
  //     getCompanies(currentPage);
  //   }
  // }, [filters, currentPage]);
  
  


  const handlePageChange = useCallback((pageNumber) => {
    console.log("Page number clicked:", pageNumber);
    setCurrentPage(pageNumber);
  }, [setCurrentPage]);

  const [purposes, setPurposes] = useState([]);

  // const getPurposes = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   try {
  //     const response = await axios.get("https://api.kseco.eu/api/sectors", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log("Sectors response:", response.data);
  //     // Transform the data to include both name and id
  //     const transformedPurposes = response.data.data.map(purpose => ({
  //       name: purpose.name,
  //       id: purpose.id.toString()
  //     }));
  //     setPurposes(transformedPurposes);
  //   } catch (error) {
  //     console.error("Error fetching sectors data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getPurposes();
  // }, []);

  const [canton, setCanton] = useState([]);

  // const getCantons = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   try {
  //     const response = await axios.get("https://api.kseco.eu/api/cantons", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log("Nationality response:", response.data); // Log response data
  //     setCanton(response.data.data); // Update nationality state with response data
  //   } catch (error) {
  //     console.error("Error fetching nationality data:", error);
  //     // Handle error (e.g., show error message to the user)
  //   }
  // };
  
  // useEffect(() => {
  //   getCantons();
  // }, []);

  const [companyType, setCompanyType] = useState([]);


  // const getCompanyType = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   try {
  //     const response = await axios.get("https://api.kseco.eu/api/companytypes", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log("Nationality response:", response.data); 
  //     setCompanyType(response.data.data); 
  //   } catch (error) {
  //     console.error("Error fetching nationality data:", error);
     
  //   }
  // };

  // useEffect(() => {
  //   getCompanyType();
  // }, []);

  const [nationality, setNationality] = useState([]);



  // const getNationality = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   try {
  //     const response = await axios.get("https://api.kseco.eu/api/nationalities", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log("Nationality response:", response.data); // Log response data
  //     setNationality(response.data.data); // Update nationality state with response data
  //   } catch (error) {
  //     console.error("Error fetching nationality data:", error);
  //     // Handle error (e.g., show error message to the user)
  //   }
  // };
  
  // useEffect(() => {
  //   getNationality();
  // }, []);

  const [service, setService] = useState([]);

  // const getService = async () => {
  //   const token = localStorage.getItem("TOKEN");
  //   console.log("Getting the services");
  //   const response = await axios.get(
  //     "https://api.kseco.ch/api/services",
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  //   setService(response.data);
  //   console.log("Services", response.data)
  // };

  // useEffect(() => {
  //   getService();
  // }, []);


  // const fetchCounts = useCallback(async () => {
  //   try {
  //     const token = localStorage.getItem("TOKEN");
  //     if (!token) {
  //       console.error('No authentication token found');
  //       return;
  //     }

  //     const headers = {
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'application/json'
  //     };

  //     const getCachedOrFetch = async (key, fetchFn) => {
  //       const cached = sessionStorage.getItem(key);
  //       if (cached) {
  //         return JSON.parse(cached);
  //       }
  //       const result = await fetchFn();
  //       sessionStorage.setItem(key, JSON.stringify(result));
  //       return result;
  //     };

  //     const statsData = await getCachedOrFetch('statistics', () => 
  //       axios.get(`${BASE_URL}/companies-statistics`, { headers })
  //     );

  //     if (statsData?.data?.data) {
  //       setCountAll(statsData.data.data.total_companies ?? 0);
  //       setCountAl(statsData.data.data.total_albanian_companies ?? 0);
  //       setCountTAL(statsData.data.data.total_today_albanian_companies ?? 0);
  //       setCountT(statsData.data.data.total_today_companies ?? 0);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching counts:', error);
  //     setCountAll(0);
  //     setCountAl(0);
  //     setCountT(0);
  //     setCountTAL(0);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchCounts();
  // }, [fetchCounts]);

  const handleServiceSearchChange = (event) => {
    const searchValue = event.target.value;
    
    const filteredServices = service
      .filter((s) => s.name.toLowerCase().includes(searchValue.toLowerCase()))
      .map((s) => s.name);
  
    setFilters((prevFilter) => ({
      ...prevFilter,
      serviceName: searchValue,
      services: filteredServices,  
    }));
  };
  

  const filterChanged = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    console.log(key, value);
  };


  const handleCitySearchChange = (event) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      city: event.target.value,
    })); // Set click to true to display the table or trigger re-fetch
  };
  

  const handleDateChange = ({ target: { value } }) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      date: value ? new Date(value) : null
    }));
  };
    //code for the cart sidebar

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (filters.officialPublication?.length > 0) {
      getCompanies();
    }
  }, [filters.officialPublication]);

  const handleCapitalChange = (key, value) => {
    console.log('Capital filter changed:', value); // Add logging
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      
      // If value is null or undefined, remove the capital filter
      if (!value) {
        delete newFilters[key];
        return newFilters;
      }

      // Otherwise set the new capital filter values
      newFilters[key] = {
        min: parseFloat(value.min) || 0,
        max: parseFloat(value.max) || null
      };
      
      return newFilters;
    });
  };

  useEffect(() => {
    // Only call getCompanies if we have valid capital filter values
    if (filters.capital) {
      getCompanies(currentPage);
    }
  }, [filters.capital]);

  const handleShareValueChange = (key, value) => {
    console.log('Share value filter changed:', value);
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      
      // If value is null or undefined, remove the share value filter
      if (!value) {
        delete newFilters[key];
        return newFilters;
      }

      // Otherwise set the new share value filter values
      newFilters[key] = {
        min: parseFloat(value.min) || 0,
        max: parseFloat(value.max) || null
      };
      
      return newFilters;
    });
  };

  useEffect(() => {
    // Only call getCompanies if we have valid share value filter
    if (filters.shareValue) {
      getCompanies(currentPage);
    }
  }, [filters.shareValue]);

  return (
    <div className="">
      <Navbar toggleSidebar={toggleSidebar}/>
      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
      <div className="main">
        <div className="sidebar">
          <ModernPublicationFilter
            title={t('dashboard.filters.publicationType')}
            value={filters.publication}
            filterKey="publication"
            onChange={handleFilterChange}
          />

          <ModernMunicipalityFilter
            title={t('dashboard.filters.municipality')}
            value={filters.municipality}
            filterKey="municipality"
            onChange={handleFilterChange}
          />

          <ModernRangeFilter
            title={t('dashboard.filters.capital')}
            filterKey="capital"
            value={filters.capital || { min: 0, max: 1000000 }}
            onChange={handleCapitalChange}
            min={0}
            max={1000000}
            step={1000}
          />

          <ModernPublicationDateFilter
            title={t('dashboard.filters.publicationDate')}
            value={filters.publicationDate}
            filterKey="publicationDate"
            onChange={handleFilterChange}
          />

          <div className="tw-space-y-4">
            <ModernPublicationRangeFilter
              title={"Insert date range"}
              value={filters.publicationDateRange}
              filterKey="publicationDateRange"
              onChange={handleFilterChange}
            />
          </div>

          <ModernRangeFilter
            title={t('dashboard.filters.shareValue')}
            filterKey="shareValue"
            value={filters.shareValue || { min: 0, max: 1000000 }}
            onChange={handleShareValueChange}
            min={0}
            max={1000000}
            step={1000}
          />

          <ModernFilterList
            title={t('dashboard.filters.legalForm.title')}
            list={[
              { id: 'Einzelunternehmen', name: t('dashboard.filters.legalForm.types.einzelunternehmen') },
              { id: 'Kollektivgesellschaft', name: t('dashboard.filters.legalForm.types.kollektivgesellschaft') },
              { id: 'Aktiengesellschaft', name: t('dashboard.filters.legalForm.types.aktiengesellschaft') },
              { id: 'Genossenschaft', name: t('dashboard.filters.legalForm.types.genossenschaft') },
              { id: 'Gesellschaft mit beschränkter Haftung', name: t('dashboard.filters.legalForm.types.gmbh') },
              { id: 'Verein', name: t('dashboard.filters.legalForm.types.verein') },
              { id: 'Stiftung', name: t('dashboard.filters.legalForm.types.stiftung') },
              { id: 'Istitut des öffentlichen Rechts', name: t('dashboard.filters.legalForm.types.institut') },
              { id: 'Zweigniederlassung', name: t('dashboard.filters.legalForm.types.zweigniederlassung') },
              { id: 'Kommanditgesellschaft', name: t('dashboard.filters.legalForm.types.kommanditgesellschaft') },
              { id: 'Zweigniederlassung auslänische Gesellschaft', name: t('dashboard.filters.legalForm.types.auslandisch') }
            ]}
            textKey="name"
            valueKey="id"
            filterKey="companyType"
            onChange={handleFilterChange}
            value={filters.companyType}
          />

          <ModernFilterList
            title={t('dashboard.filters.canton')}
            list={canton}
            textKey="name"
            valueKey="id"
            filterKey="canton"
            onChange={onCheckBoxClicked}
            value={filters.canton || []} 
          />

          <ModernFilterList
            title={t('dashboard.filters.nationality')}
            list={nationality}
            textKey="nationality"
            valueKey="nationality"
            filterKey="nationality"
            onChange={onCheckBoxClicked}
            value={filters.nationality}
          />

          <ModernFilterList
            title={t('dashboard.filters.sectors')}
            list={purposes}
            textKey="name"
            valueKey="id"
            filterKey="purposes"
            onChange={onCheckBoxClicked}
            value={filters.purposes}
          />

          <ModernServiceFilter
            title={t('dashboard.filters.service')}
            value={filters.serviceName}
            filterKey="serviceName"
            onChange={handleFilterChange}
          />

          <ModernDateFilter
            title={t('dashboard.filters.calendar')}
            value={filters.date}
            filterKey="date"
            onChange={(key, value) => handleDateChange({ target: { value }})}
          />

          <ModernCityFilter
            title={t('dashboard.filters.city')}
            value={filters.city}
            filterKey="city"
            onChange={handleFilterChange}
          />

          <ModernZefixDateRangeFilter
            title={t('dashboard.filters.publicationDateRange')}
            value={filters.zefixDateRange}
            filterKey="zefixDateRange"
            onChange={handleFilterChange}
          />
        </div>


        {cleanedFilter.publication ? (
          <div>
            <Publication
              filter={cleanedFilter}
              setFilter={setFilters}
              UnSelectAll={UnSelectAll}
              // setSearchTerm={setSearchTerm}
              setSearchTerm={(term) => setFilters({ ...filters, searchTerm: term })}
              Link={Link}
              bot={bot}
              Pagination={Pagination}
              totalData={totalData}
              searchTerm={searchTerm}
              tableRef={tableRef}
              handleExcel={handleExcel}
              a={a}
              loading={loading}
              companies={companies}
              filterChanged={filterChanged}
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 

              getCompanies={getCompanies}
              dateRange={dateRange}
            />
          </div>
        ) : (


          <div className="col-lg-12">
            <div className="crm-section">
              <button
                className="crm-card first col-lg-4"
                onClick={() =>
                  setFilters({
                    publication: "today",
                    canton: filters.canton,
                    companyType: filters.companyType,
                    page: 1,
                    size: 10,
                    purposes: filters.purposes,
                    services: filters.services,
                    nationality: filters.nationality,
                  })
                }
              >
                <div className="crm-text ">
                  <h1>
                    <span
                      className="timer counter alt-font appear"
                      data-to="600"
                      data-speed="7000"
                    >
                      <CountUp end={countT || 0} />
                    </span>

                    <p className="pu">Today Publications</p>
                  </h1>
                </div>
                <div className="crm-icon">
                  <i class="icon-graph success font-large-2 float-left"></i>{" "}
                </div>
              </button>

              <button
                className="crm-card second col-lg-4 "
                onClick={() =>
                  setFilters({
                    publication: "Today Publications",
                    canton: filters.canton,
                    companyType: filters.companyType,
                    page: 1,
                    size: 10,
                    purposes: filters.purposes,
                    nationality: filters.nationality,
                  })
                }
              >
                <div className="crm-text ">
                  <h1 className="ha">
                    <span
                      className="timer counter alt-font appear"
                      data-to="600"
                      data-speed="7000"
                    >
                      <CountUp end={countAl || 0} />
                    </span>
                    <p className="pu"> All Albanian Publication</p>
                  </h1>
                </div>
                <div className="crm-icon">
                  <i class="icon-bar-chart customize-icon font-large-2 float-right"></i>
                </div>
              </button>
              <button
                className="crm-card third col-lg-4 "
                onClick={() =>
                  setFilters({
                    publication: "All Publications",
                    canton: filters.canton,
                    companyType: filters.companyType,
                    page: 1,
                    size: 10,
                    purposes: filters.purposes,
                    services: filters.services,
                    nationality: filters.nationality,
                  })
                }
              >
                <div className="crm-text ">
                  <h1 className="ha">
                    <span
                      className="timer counter alt-font appear"
                      data-to="600"
                      data-speed="7000"
                    >
                      <CountUp end={countAll || 0} />
                    </span>
                    <p className="pu"> All Publication</p>
                  </h1>
                </div>
                <div className="crm-icon">
                  <i class="icon-pie-chart warning font-large-2 float-right"></i>
                </div>
              </button>
            </div>
            <div className="col-lg-12">
              <div className="charts">
                <ApexChart />
                <Bar />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};          


  export function getFilter(filterChanged, filter, publicationList) {
    return (
      <FilterList
        title="Legal Form"
        list={publicationList}
        textKey="name"
        valueKey="name"
        filterKey="companyType"
        onChange={filterChanged}
        value={filter.companyType}
      />
    );
  }

 
  
export default Dashboard;